
const digitalOceanURL =
    "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const About_Section_Image = `${digitalOceanURL}/Portal_Image/Offer_Section.png`;
const wireframe = `${digitalOceanURL}/Portal_Image/wireframe.png`;

export default function Offer_Section() {
    return (
        <div className="sm:mt-4 relative section_spacing2">
            <img
                src={wireframe}
                alt="wireframe"
                loading="lazy"
                className="hidden md:block absolute right-0 top-0"
            />

            <div className="grid lg:grid-cols-2 lg:mx-12 sm:grid-cols-1 sm:gap-8 About-box items-center justify-center relative lg:top-36">
                <div className="about-text-box text-center lg:text-left lg:mx-0 md:mx-0 mx-2">
                    <div>
                        <button className="rounded-full text-black p-1 h-[37px] w-32 mb-2 mt-2 About-btn font-[16px]">
                            Offer Portal{" "}
                        </button>
                    </div>
                    <div className="mt-2">
                        <h2 className="font-semibold text-[32px] md:text-[48px] lg:text-[48px] About-Heading">
                            Streamline  <br />Your Offer Process
                        </h2>
                    </div>
                    <div className="mt-4">
                        <p className="font-[poppins] font-[16px]">
                            The Offer Experience Portal is an avant-garde solution that reinvents the hiring workflow. By integrating a refined, digital approach, this portal empowers HR professionals to transcend antiquated practices, delivering efficiency, transparency, and an elevated candidate experience. Experience unparalleled control and adaptability, as the portal addresses persistent hiring dilemmas head-on, paving the way for a new era of recruitment sophistication.
                        </p>
                    </div>
                    {/*  <div className="mt-4">
                        <button className="rounded-full text-white w-[134px] h-[52px] mb-2 mt-2 Read-More-btn font-[16px]">
                            Read More{" "}
                        </button>
                    </div> */}
                </div>
                <div className="flex justify-center lg:mx-0 md:mx-0 mx-2">
                    <img loading="lazy" src={About_Section_Image} alt="About_image" />
                </div>
            </div>
        </div>


    );
}
