import React from "react";
import Portal_Section_3 from './Portal_Section_3';
import GR_Section from './GR_Section'
import ButtonComponent from '../../Components/Buttons/ButtonComponent';
import TestimonialSection from '../HomePage/TestimonialSection/TestimonialSection';
import FaqComponent from '../../Components/Footer/FaqComponent/FaqComponent';
import HelpCard from '../../Components/Footer/HelpCenterCard/HelpCard';
import BgvStepsSection from '../ItPage/BgvStepsSection/BgvStepsSection';
import BrandCarousel from "../ItPage/BrandCarousel/BrandCarousel";

const digitalOceanURL = "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const GR_Portal_Image = `${digitalOceanURL}/Portal_Image/GR_Portal_Img.png`;
const GR_Portal_Small_Image = `${digitalOceanURL}/Portal_Image/GR_Portal_small_Img.png`;
export default function GR_Portal() {
  return (
    <div>
      <div className=" relative  blog_bg_image background_color">
        {/* ---------------------------show this for lg and md device------------------------------------- */}
        <div className="lg:relative    flex flex-col   lg:mx-12 lg:top-2 top_image_gap3">
          <div className=" flex flex-col lg:relative  lg:right-10 leading-tight z-[2] GR-portal-box ">
            <div className="p-2 mt-8  lg:ml-16 top_spacing">
              <div className="text-wrap mt-14 lg:mt-0 md:mt-20 text-heading-container  font-semibold font-[poppins] text-center heading">
                <h2 className="text-[32px] md:text-[46px] lg:text-[46px] text">
                  Introducing the Hirestar.io
                  <span className="text-[#1783C4]"><br /> Grievance Reporting Portal</span>
                </h2>
              </div>
              <div className="text-wrap px-2 lg:px-0 md:px-0  font-[poppins] font-[16px] mt-2 md:mt-6 lg:mt-6 text-center paragraph">
                <p>
                  Streamline hiring with secure, blockchain-based background
                  checks <br className="hidden md:block lg:block " />
                  Trust Hirestar.io for verified and transparent employee
                  screening
                </p>
              </div>
            </div>

            {/* --------------------------buttons-------------------- */}
            <ButtonComponent />
          </div>

          {/* -------------------------show this for large device-------------------------------------- */}
          <div className="large-image">
            {/* Home Image */}
            <img
              className=" sm:mb-12 lg:relative w-full home-image"
              alt="Home"
              src={GR_Portal_Image}
              loading="lazy"
            />
          </div>

          {/* -------------------------show this for small device-------------------------------------- */}
          <div className="relative w-full max-w-sm mx-auto small-image">
            <div className="overflow-hidden rounded-lg">
              <div className="flex transition-transform duration-300 ease-in-out mb-20">
                <img loading="lazy"
                  src={GR_Portal_Small_Image}
                  alt="small_screen_image"
                  className="w-full h-auto flex-shrink-0"
                />
              </div>
            </div>
          </div>

          {/* ------------------------dot code to show  current image -------------------- */}
        </div>
      </div>
      <div className="mt-20">
        <BrandCarousel />
      </div>
      <div className="top_spacing">
        <GR_Section />
      </div>
      <Portal_Section_3 heading="Grievance Reporting Portal" Paragraph='Tackle hiring challenges head-on. The portal addresses common issues like moonlighting, offer shopping, and late joining by streamlining the offer process. Stay ahead of potential pitfalls and ensure a smooth transition from offer to onboarding' section="GR_Portal" />


      <BgvStepsSection />
      <div className="">
        <TestimonialSection />
      </div>
      <div className="">
        <FaqComponent />
      </div>
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>


  );
}
