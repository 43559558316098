import ScrollableMenu from "./ScrollMenu";
import {memo} from "react";
const ScrollMenuContainer = ({
  menuItems,
  handleServiceClickIndex,
  activeIndex,
  setActiveIndex
}) => {
  return (
    <div className="w-full mx-auto">
      <h2 className=" font-semibold mb-6  md:text-[44px] lg:text-[48px] text-center section_headings">
        Our <span className="text-[#1681C3]">Services</span>
      </h2>
      <ScrollableMenu
        menuItems={menuItems}
        handleServiceClickIndex={handleServiceClickIndex}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </div>
  );
};

export default  memo(ScrollMenuContainer);
