const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const IT_Service_1 = `${digitalOceanURL}/Industry/Sector/IT_Service_1.png`;
const IT_Service_2 = `${digitalOceanURL}/Industry/Sector/IT_Service_2.png`;
const IT_Service_3 = `${digitalOceanURL}/Industry/Sector/IT_Service_3.png`;
export default function BgvServiceSection({ Sector }) {
  return (
    <div className="mt-32">
      <h2 className=" mb-28 IT_Headings">
        What Makes Us Stand Out
      </h2>
      <div className="grid xl:grid-cols-3 sm:grid-cols-1 lg:gap-12 gap-28 md:mx-16 relative justify-center mx-4">
        {/* Card 1: Employee Screening */}
        <div className="IT_Section relative flex flex-col justify-between items-center md:mb-12 h-full  bottom_spacing">
          <div className="IT_Service_box rounded-full absolute top-0 transform translate-y-[-50%] flex justify-center items-center">
            <img loading="lazy" src={IT_Service_1} alt="IT_Service_1" className="mr-3" />
          </div>

          <div className="text-center card_padding flex flex-col items-center h-full  ">
            <h6 className="IT_Service_Heading">Employee Screening</h6>
            <p className="IT_service_Para mt-4 mb-2 mx-2">
              Employee screening involves verifying candidates' backgrounds, qualifications, and employment history to ensure trust, compliance, and a secure hiring process.
            </p>
          </div>
        </div>

        {/* Card 2: Transparency */}
        <div className="IT_Section relative flex flex-col justify-between items-center md:mb-12 h-full bottom_spacing">
          <div className="IT_Service_box rounded-full absolute top-0 transform translate-y-[-50%] flex justify-center items-center">
            <img loading="lazy" src={IT_Service_2} alt="IT_Service_2" className="mr-3" />
          </div>

          <div className="text-center card_padding flex flex-col items-center h-full">
            <h6 className="IT_Service_Heading">Transparency</h6>
            <p className="IT_service_Para mt-4 mb-2 mx-2">
              Transparency involves openly sharing information to ensure clarity and trust in employee screening, allowing accessible and verifiable details that build confidence and reduce miscommunication.
            </p>
          </div>
        </div>

        {/* Card 3: Smooth Process */}
        <div className="IT_Section relative flex flex-col justify-between items-center md:mb-12 h-full bottom_spacing">
          <div className="IT_Service_box rounded-full absolute top-0 transform translate-y-[-50%] flex justify-center items-center">
            <img loading="lazy" src={IT_Service_3} alt="IT_Service_3" className="mr-3" />
          </div>

          <div className="text-center card_padding flex flex-col items-center h-full">
            <h6 className="IT_Service_Heading">Smooth Process</h6>
            <p className="IT_service_Para mt-4 mb-2 mx-2">
              The self-verification portal allows candidates to easily verify their employment history and educational qualifications, streamlining the process and promoting transparency in hiring.
            </p>
          </div>
        </div>
      </div>


    </div>
  );
}
