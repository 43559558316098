import ContactHeader from "./components/ContactHeader";
import ContactCard from "./components/ContactCard";
import ContactUsForm from "./components/ContactUsForm";
import "./components/contact_us.style.css";
const ContactUsPage = () => {
  return (
    <div >
      <ContactHeader />
      <ContactCard />
      <ContactUsForm />
    </div>
  );
};

export default ContactUsPage;
