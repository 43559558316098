import React, { useEffect, useRef, useState } from "react";

const BlogContent = ({ content }) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, []);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div className="max-w-5xl  mx-auto sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto 2xl  p-6  border-t border-gray-500">
      <div
        className="relative overflow-hidden"
        style={{ maxHeight: showFullContent ? "none" : "800px" }}
      >
        <div
          ref={contentRef}
          className={`space-y-6 ${!showFullContent && "pb-16"}`}
        >
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
        {!showFullContent && (
          <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-white to-transparent"></div>
        )}
      </div>
      <button
        onClick={toggleContent}
        className="flex items-center hover:shadow-lg justify-center w-48 mx-auto py-3 text-sm font-medium mt-6 text-white rounded-[61px] bg-[#1681C3]  transition-colors focus:outline-none   focus:ring-offset-2"
      >
        {showFullContent ? "View Less" : "View More"}
      </button>
    </div>
  );
};

export default BlogContent;
