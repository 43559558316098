export const DemoFormValidation = (request_body) => {
  if (Object.keys(request_body).length === 0) {
    return "Missing all required parameters";
  }

  let data_obj = {
    first_name: undefined,
    last_name: undefined,
    company_email: undefined,
    company_name: undefined,
    mobile_number: undefined,
    hiring_forecast: undefined,
    number_of_employees: undefined,
    captcha: undefined,
    purpose_of_contact: undefined
  };

  let isrequest_bodyInvalid = false;

  const checkForMissingKey = checkingKeys(
    request_body,
    data_obj,
    isrequest_bodyInvalid
  );
  if (checkForMissingKey) {
    return checkForMissingKey;
  }

  if (Object.keys(request_body).length > 9) {
    return "body parameter limit exceeded, Only required parameters are allowed";
  }

  const getting_empty_key = checkFields(request_body);
  if (getting_empty_key) {
    return `Field [${getting_empty_key}] is missing or has a value of undefined or null.`;
  }
};

let isrequest_bodyInvalid = false;

export function checkingKeys(request_body, data_obj) {
  // console.log(request_body,data_obj)
  for (let key in data_obj) {
    if (!request_body.hasOwnProperty(key)) {
      isrequest_bodyInvalid = true;
      //missing key
      return "Missing required request parameter: [" + key + "]";
    }
  }
}

export function checkFields(request_body) {
  for (const key in request_body) {
    // console.log(">>>>>>>>>>>>", key);
    if (request_body.hasOwnProperty(key)) {
      if (
        request_body[key] === "" ||
        request_body[key] === null ||
        request_body[key] === undefined
      ) {
        return key;
      }
    }
  }
}
