import React from "react";
import { Search } from "lucide-react";
const BlogSearchInput = ({ handleSearch, inputValue }) => {
  return (
    <div className="md:w-[48%] lg:w-[32%] max-w-xs  md:mt-2 lg:mt-3 sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto p-4">
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
          <Search className="h-6 w-6 cursor-pointer text-gray-400" />
        </div>
        <input
          type="text"
          name="search"
          id="search"
          value={inputValue}
          onChange={handleSearch}
          className="block cursor-pointer rounded-full w-full pl-14 pr-6 py-3 border border-[#4ABEFF] leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-[#2095D2] focus:border-[#2095D2] sm:text-sm"
          placeholder="Search by keywords"
        />
        <div className="absolute inset-y-0 left-0 px-12 my-1 flex items-center pointer-events-none">
          <div className="h-full border-l border-gray-400"></div>
        </div>
      </div>
    </div>
  );
};

export default BlogSearchInput;
