import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../Components/Buttons/ButtonComponent";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const Home_Image = `${digitalOceanURL}/Home_Page_Image.png`;
const linearImage = `${digitalOceanURL}/linear-image.png`;
const img1 = `${digitalOceanURL}/HomeAssets/img1.svg`;
const img2 = `${digitalOceanURL}/HomeAssets/img2.svg`;
const img3 = `${digitalOceanURL}/HomeAssets/img3.svg`;
const img4 = `${digitalOceanURL}/HomeAssets/img4.svg`;
const images = [img1, img2, img3, img4];
const HomePageHeroSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const navigate = useNavigate();
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <div className=" relative overflow-hidden background_color">
      {/* ---------------------------show this for lg and md device------------------------------------- */}
      <div className="lg:relative   lg:top-0 flex flex-col lg:ml-12">
        <div className=" flex flex-col lg:relative  lg:right-10 leading-tight z-[2] main-box ">
          <div className="home_spacing">
            <div className="text-wrap mt-14 lg:mt-0 md:mt-20 text-heading-container  font-semibold font-[poppins] text-center heading">
              <h2 className="text-[32px] md:text-[40px] lg:text-[46px]">
                Secure Your Hiring Process With
              </h2>
              <h2 className="text-[32px] md:text-[40px] lg:text-[46px]">
                Blockchain Technology
              </h2>
            </div>
            <div className="text-wrap px-2 lg:px-0 md:px-0  font-[poppins] sm:font-[12px] font-[16px] mt-4 md:mt-6 lg:mt-6 text-center paragraph">
              <p>
                Streamline hiring with secure, blockchain-based background
                checks <br className="hidden md:block lg:block " />
                Trust Hirestar.io for verified and transparent employee
                screening
              </p>
            </div>
          </div>

          {/* --------------------------buttons-------------------- */}
          {/* <div className="lg:relative items-center text-wrap font-[poppins] text-center gap-4 flex flex-col md:flex-row lg:flex-row justify-center lg:top-10 btn-box">
            <button className="rounded-full home_button w-44 text-black p-2 lg:w-[15%] flex items-center justify-center">
              Speak To An Expert
            </button>

            <button className="rounded-full home_button w-44 text-black p-2 lg:w-[13%] flex items-center justify-center">
              <span className="mr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z"
                  />
                </svg>
              </span>
              Watch Video
            </button>
          </div> */}
          <ButtonComponent />
        </div>

        {/* -------------------------show this for large device-------------------------------------- */}
        <div className="large-image">
          {/* Home Image */}
          <img
            className="home-image sm:mb-12 lg:relative  w-full home_image"
            alt="home-image"
            src={Home_Image}
          />

          {/* Top-left Gradient Image */}
          <img
            src={linearImage}
            alt="Gradient"
            className="gradient-image-top-left"
          />

          {/* Bottom-right Gradient Image */}
          <img
            src={linearImage}
            alt="Gradient"
            className="gradient-image-bottom-right"
          />
        </div>

        {/* -------------------------show this for small device-------------------------------------- */}
        <div className="relative w-full max-w-sm mx-auto small-image">
          {" "}
          {/* Hide on screens larger than small */}
          <div className="overflow-hidden  rounded-lg ">
            <div
              className="flex  transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {images.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-auto  flex-shrink-0"
                />
              ))}
            </div>
          </div>
          {/* ------------------------dot code to show  current image -------------------- */}
          {/* <div className="absolute bottom-2 left-0 right-0">
        <div className="flex items-center justify-center gap-3">
          {images.map((_, index) => (
            <button
              key={index}
              className={`w-4 h-4 rounded-full transition-colors duration-200 ${
                index === currentIndex ? "bg-red-500" : "bg-white/60"
              }`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div> */}
        </div>
      </div>
    </div>
  );
};

export default HomePageHeroSection;
