import ContactHireStar from "../../../Components/ContactHire/ContactHirestar";
const WhyHirestarSection = () => {
  return (
    <div>
      <ContactHireStar />
    </div>
  );
};

export default WhyHirestarSection;
