import { useState } from "react";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const hideIcon = `${digitalOceanURL}/FooterAssets/FaqAssests/minus.svg`;
const showIcon = `${digitalOceanURL}/FooterAssets/FaqAssests/Plus.svg`;

// ../../../../assets/SingleBlogImg
const questions = [
  {
    id: 1,
    question:
      "What is Experience and Employee Background Verification powered by Blockchain?",
    answer:
      "We use blockchain technology to create offer letters and conduct employment verification. When a company generates offer letters through our portal, it can see how many offers a candidate currently holds. This insight helps the company determine whether the candidate is genuinely interested in joining or simply exploring multiple offers. Additionally, if a candidate's previous employers have already verified their employment details, the new employer can streamline the verification process, saving valuable time and resources that would otherwise be spent re-verifying each past employer."
  },

  {
    id: 2,
    question:
      "How does blockchain technology enhance the verification process?",
    answer:
      "Blockchain technology improves the verification process by providing a secure and transparent decentralised ledger. It ensures data integrity and immutability, making it tamper-resistant. With smart contracts and consensus mechanisms, blockchain builds trust in the verification process, significantly reducing the risk of fraud or manipulation."
  },

  {
    id: 3,
    question:
      "Why is blockchain verification more secure than traditional methods?",
    answer:
      "Blockchain verification is more secure than traditional methods due to its unique features. Its immutability ensures data integrity, making it nearly impossible to tamper with. Decentralisation minimises vulnerabilities associated with centralised systems, while cryptographic techniques secure transactions. Additionally, transparency and smart contracts enhance trust by providing real-time visibility and automating verification processes. These combined features make blockchain a robust and secure solution that surpasses many traditional verification methods."
  },
  {
    id: 4,
    question:
      "What types of employee information can be verified using this service?",
    answer:
      "Our service verifies various types of employee information, including employment history, job titles, dates of employment, and salary details. It can also verify educational qualifications, professional certifications, and relevant licences. Our platform ensures a comprehensive and reliable verification process, helping employers confirm critical details about potential hires and promoting transparency and trust."
  },
  {
    id: 5,
    question: "How long does the verification process take?",
    answer:
      "If blockchain records already exist, the verification process for degrees and past employment details can be completed within minutes. If such records are unavailable, the process may take 2-7 days, depending on how quickly universities and previous employers respond. Other verifications, such as PAN, Aadhaar, and driving licences (with or without facial recognition), can also be done within minutes. However, crime checks, which are the most time-consuming, will be completed within a maximum of 2 days."
  },
  {
    id: 6,
    question:
      "Is the information stored on the blockchain accessible by anyone?",
    answer:
      "No, only individuals who have subscribed to the services available on the blockchain can access the information stored there."
  }
];

const FaqComponent = () => {
  const [visibleQuestion, setVisibleQuestion] = useState(null);

  // Toggle function to show/hide answers
  const toggleAnswer = (id) => {
    setVisibleQuestion(visibleQuestion === id ? null : id);
  };
  return (
    <div className="footer-faq-wrapper mt-16 mb-4 relative max-w-3xl mx-auto  ">
      <div className="faq-heading  text-center ">
        <h1 className=" font-semibold mb-6 section_headings md:text-[40px] lg:text-[48px] text-[#2A2A2C]">FAQs</h1>
        <p className="font-normal text-base mx-1.5 mt-4 mb-12 sector_head">
          The FAQ section provides insights into blockchain-powered employee
          background verification, highlighting enhanced security, transparency,
          and process efficiency.{" "}
        </p>
      </div>
      {/* ----------------------Faq list----------------------------------- */}
      <div className="faq-list mx-3">
        <div>
          {questions.map(({ id, question, answer }) => (
            <div
              key={id}
              className="mb-3 shadow-md p-3 cursor-pointer bg-white rounded-md border"
              onClick={() => toggleAnswer(id)}
            >
              <span className="flex items-center justify-between w-full">
                <h2 className="font-medium faq_head">{question}</h2>
                <div className="flex-shrink-0">
                  <img loading="lazy"
                    src={visibleQuestion === id ? hideIcon : showIcon}
                    alt={visibleQuestion === id ? "not visible" : "visible"}
                    className={visibleQuestion === id ? "" : "h-[0.8rem]"} // Apply height only to show icon
                  />
                </div>
              </span>

              {/* Added max-height and overflow for scrollable content on mobile */}
              <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${visibleQuestion === id ? "max-h-40" : "max-h-0"
                  }`}
              >
                {visibleQuestion === id && (
                  <div className="mt-2 text-base font-normal text-[#111111] w-full text-start overflow-y-auto max-h-[200px] md:max-h-none">
                    <p>{answer}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>



    </div>
  );
};

export default FaqComponent;
