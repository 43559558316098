import TestimonialSlider from "./TestimonialSlider";
const TestimonialComponent = () => {
  return (
    <div className=" mt-44 md:mt-28 lg:mt-28 lg:mx-20 ">
      <div className=" text-center  slider-heading-container lg:mb-14 mb-0">
        <h2 className=" font-semibold mb-6 mx-3   md:text-[44px] lg:text-[48px] section_headings">
          What <span className="text-[#1B8ACA]">Our Clients</span> Say
        </h2>
      </div>
      <TestimonialSlider />
    </div>
  );
};

export default TestimonialComponent;
