import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import ServicesCardSection from "../ItPage/ServicesCardSection/ServicesCardSection";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import BgvStepsSection from "../ItPage/BgvStepsSection/BgvStepsSection";
import BgvServiceSection from "../ItPage/BgvServiceSection/BgvServiceSection";
import BrandCarousel from "../ItPage/BrandCarousel/BrandCarousel";
const digitalOceanURL = "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";
const bg_image = `${digitalOceanURL}/About_Us_img/About_bg.png`;
const gig_worker = `${digitalOceanURL}/Sector/Gig_Worker.png`;




export default function GigWorkers() {
  return (
    <div>
      <div className=" relative  background_color bottom_space">
        {/* Image that is hidden on small screens */}
        <img
          src={bg_image}
          alt="gig-workers-img"
          className="absolute  object-cover w-full lg:h-[150%] h-[110%] md:h-[120%] bg_curve_img"
        />

        <div className="mb-16 relative  w-full h-full md:top-28 lg:top-0">
          <div className="grid grid-cols-12 gap-4 justify-items-center relative top-0 lg:top-32 lg:mx-20 xl:mx-32 2xl:mx-40 items-center">
            {/* Text container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-7 w-full Banking_heading font-semibold font-[poppins] top_spacing ">
              <span className="industry_info">Gig Workers</span>
              <h1 className="w-full text-[32px] md:text-[40px] lg:text-[46px]">

                Seamless Background Verification for Gig Workers with Hirestar
              </h1>
              <p className="about-text-para mt-2 ">

                Hirestar’s platform ensures fast, accurate background checks for
                gig workers, enhancing reliability and minimizing risk.{" "}
              </p>

              <div className="mt-4">
                <ButtonComponent />
              </div>

            </div>

            {/* Image container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-5 relative   lg:top-8 ">
              <img src={gig_worker} alt="about-girl" className="w-full h-auto object-cover" />
            </div>
          </div>
        </div>
      </div>

      <div className="top_Space">
        <BrandCarousel />
      </div>
      {/* 3 section */}
      <BgvServiceSection Sector="GIG Workers" />
      {/* 4 section */}
      {/* <BgvStepsSection /> */}
      {/* 5 section */}
      <ServicesCardSection Sector="GIG Workers" Sector_cards='manufacture' />
      <div className="mb-48">
        {<BgvStepsSection />}
      </div>
      <FaqComponent />
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>
  );
}
