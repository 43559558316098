import React, { useEffect } from "react";
import {memo} from "react";
import { ChevronsRight, ChevronsLeft } from "lucide-react";

const Pagination = ({ totalPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [];

  const startPage = Math.max(currentPage - 2, 1);
  const endPage = Math.min(startPage + 4, totalPages);
  useEffect(() => {
    // const handleScrollToSection = () => {
    const sectionElement = document.getElementById("my-section");
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop,
        behavior: "smooth"
      });
    }
    // };
  }, [currentPage]);

  // check if current page greater than 1
  if (currentPage > 1) {
    pageNumbers.push(
      <button
        key="prev"
        className="px-1 md:px-3 lg:px-3 flex items-center py-2 mx-1 text-lg font-bold  border border-[#7C7C7C] rounded-sm hover:bg-gray-50"
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        <span className="inline-block ">
          <ChevronsLeft />
        </span>
        Prev
      </button>
    );
  }

  // add page numbers
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(
      <button
        key={i}
        onClick={() => setCurrentPage(i)}
        className={`px-1.5 md:px-4 lg:px-4 py-1 mx-1 rounded text-lg ${currentPage === i
            ? "bg-[#2095D2]  border-none font-bold text-white"
            : "font-bold  border border-[#7C7C7C]   rounded-sm"
          }`}
      >
        {i}
      </button>
    );
  }

  // check if current page less than total pages
  if (currentPage < totalPages) {
    pageNumbers.push(
      <button
        key="next"
        className=" px-1 md:px-3 lg:px-3 flex items-center py-2 text-lg font-bold mx-1 border border-[#7C7C7C] rounded-sm hover:bg-gray-50"
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        Next
        <span className="inline-block ">
          <ChevronsRight />
        </span>
      </button>
    );
  }

  return <div className="flex justify-center mt-5">{pageNumbers}</div>;
};

export default memo(Pagination);
