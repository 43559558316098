import React from "react";
import "./scroll.style.css";
import { Link } from "react-router-dom";
const ScrollableMenu = ({
  menuItems,
  handleServiceClickIndex,
  activeIndex
}) => {
  return (
    <div className="max-w-[945px] mx-auto rounded-[21px] bg-[#EDF8FF] overflow-hidden">
      <div className="overflow-x-auto overflow-hidden scrollbar-hide whitespace-nowrap py-[1.5px] relative">
        <ul className="flex justify-start space-x-2 py-0.5 item-lists px-4">
          {/* Menu Items */}
          {menuItems.map((item, index) => (
            <li key={item.id} className="inline-block">
              <Link
                onClick={() => handleServiceClickIndex(index)}
                className={`text-black cursor-pointer font-normal rounded-[28px] text-base lg:px-4 lg:py-2 md:px-4 md:py-2 px-4 py-3 transition-all duration-500 ease-in-out transform 
              ${
                activeIndex === index
                  ? "activeTab text-white shadow-xl scale-105"
                  : ""
              } 
              hover:text-white hover:shadow-xl hover:scale-105`}
                style={{
                  transitionProperty:
                    "background-color, color, transform, box-shadow"
                }}
              >
                {item.serviceName}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ScrollableMenu;
