import { useState, useEffect } from "react";
import CaptchaField from "./CaptchaField";
import { useNavigate } from 'react-router';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { DemoFormValidation } from '../../../Functions/functions';
const ContactUsForm = () => {
  const [formdata, setFormdata] = useState({});
  const [captcha, setCaptcha] = useState("");
  const [inputcaptcha, setInputCaptcha] = useState("");
  const [iscorrect, setIsCorrect] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidCompany, setIsValidCompany] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const navigate = useNavigate()

  function randomCaptcha(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleChange = (e) => {
    // const key_name = e.target.name;
    // setFormdata({ ...formdata, [key_name]: e.target.value })
    const keyName = e.target.name;
    const value = e.target.value;

    // Update the form data state
    setFormdata({ ...formdata, [keyName]: value });

    // Check if the email is in the correct format
    if (keyName === 'company_email') {
      setIsValidEmail(EmailValidation(value))
      /* setIsValidEmail(/^[\w-\.]+@gmail.com$/.test(value)); */
    }

    if (keyName === 'first_name') {
      setIsValidFirstName(/^[a-zA-Z]+$/.test(value));
    }
    if (keyName === 'last_name') {
      setIsValidLastName(/^[a-zA-Z]+$/.test(value));
    }

    if (keyName === 'company_name') {
      setIsValidCompany(/^[a-zA-Z &]+$/.test(value));
    }

    if (keyName === 'mobile_number') {
      setIsValidPhone(/^[0-9]+$/.test(value));
    }


  }

  function EmailValidation(email) {
    // Regular expression for a basic email validation
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    return emailRegex.test(email);
  }

  const DemoSubmit = (e) => {
    e.preventDefault();
    if (isValidEmail === false) {
      toast.error("Enter a valid email or cannot be empty", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-error",
      });
      return;
    }
    if (isValidFirstName === false) {
      toast.error("Enter a valid first name or cannot be empty", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-error",
      });
      return;
    }
    if (isValidLastName === false) {
      toast.error("Enter a valid last name or cannot be empty", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-error",
      });
      return;
    }
    if (isValidCompany === false) {
      toast.error("Enter a valid company name or cannot be empty", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-error",
      });
      return;
    }
    if (isValidPhone === false || formdata['mobile_number'].length !== 10) {
      toast.error("Enter a valid phone number or cannot be empty", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-error",
      });
      return;
    }
    formdata['captcha'] = inputcaptcha
    const validation_error = DemoFormValidation({ ...formdata, purpose_of_contact: "info" });
    // console.log(validation_error);
    if (validation_error) {
      toast.error(validation_error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-error",
      });
      return;
    }

    // axios.post(`${process.env.REACT_APP_OFFERS_URL}/offers/demo/request`, { ...formdata, purpose_of_contact: "info" }).then((res) => {
    //   console.log(res.data)
    //   toast.success(res.data.message, {
    //     position: "top-center",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     className: "toast-success"
    //   });
    //   setTimeout(() => {
    //     navigate("/")
    //   }, 4000)
    // })
    delete formdata["captcha"]
    if (captcha === inputcaptcha) {
      axios.post(`${process.env.REACT_APP_OFFERS_URL}/offers/demo/request`, { ...formdata, purpose_of_contact: "info" })
        .then((res) => {
          // console.log(res.data)
          toast.success(res?.data?.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "toast-success"
          });
          setTimeout(() => {
            navigate("/")
          }, 4000)
        }).catch((err) => {
          console.log(err)
          toast.error(err?.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "toast-success"
          });
        })
      // console.log(formdata)
    } else {
      setIsCorrect(false)
    }
  }

  useEffect(() => {
    setCaptcha(randomCaptcha(6));
  }, []);


  return (
    <> <ToastContainer />
      <div className="relative mb-[24rem] z-10 footer_margin_bottom mx-2">
        <h1 className="text-5xl mb-8 text-center font-semibold leading-none text-[#3A3C3D] max-md:text-4xl footer_margin_768">
          Schedule a personalized demo call <br className="max-md:hidden" /> with our experts
        </h1>

        <div className=" max-w-6xl mx-auto contact-container">
          <div className="flex flex-col mx-4  lg:mx-0 md:mx-4 items-center justify-center min-h-screen absolute left-0 right-0 top-[7rem]">
            {/* Form Container */}
            <div className="w-full max-w-3xl  p-8 bg-white shadow-lg rounded-[24px]">
              {/* Heading */}
              <h2 className="text-5xl text-center text-[#3A3C3D] font-semibold mb-2 mt-8">
                Contact Us
              </h2>
              {/* Subheading */}
              <p className="text-center md:w-2/3 lg:w-2/3 mx-auto font-normal text-[#4D4F4F] mb-6">
                Have questions? Contact us to schedule a demo, and we'll connect you with the right expert.
              </p>

              {/* Form */}
              <form className="space-y-6">
                {/* Two Input Fields in One Row */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="first-name"
                      className="text-[#797D82] text-base font-normal mb-1"
                    >
                      First Name
                    </label>

                    <input
                      type="text"
                      id="first-name"
                      className="w-full p-2 border border-[#C8C9C9] rounded-lg focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]"
                      name='first_name'
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="last-name"
                      className="text-[#797D82] text-base font-normal mb-1"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="last-name"
                      className="w-full p-2 border border-[#C8C9C9] rounded-lg focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]"
                      name='last_name'
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* Two Input Fields in One Row */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="email"
                      className="text-[#797D82] text-base font-normal mb-1"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="w-full p-2 border border-[#C8C9C9] rounded-lg focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]"
                      name='company_email'
                      onChange={handleChange}
                    />
                    {(formdata.company_email && !isValidEmail) ? < p className='text-xs mb-2 text-red-500'>Email address is not in correct format for Gmail</p> : null}
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="company-name"
                      className="text-[#797D82] text-base font-normal mb-1"
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      id="company-name"
                      className="w-full p-2 border border-[#C8C9C9] rounded-lg focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]"
                      name='company_name'
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* Input for Phone Number with Dropdown for Dialing Code */}
                <div className="flex flex-col">
                  <label
                    htmlFor="phone-number"
                    className="text-[#797D82] text-base font-normal mb-1"
                  >
                    Mobile No.
                    <p className="text-[#9C9EA2] text-sm my-1 font-normal">
                      include dialing code eg. +91 for India
                    </p>
                  </label>

                  <div className="flex border border-[#C8C9C9] rounded-lg focus-within:border-[#1571B7] focus-within:shadow-sm focus-within:shadow-[#1571B7]">
                    {/* Dialing Code Dropdown */}
                    <select
                      id="dialing-code"
                      className="p-2 border-r-0 appearance-none bg-transparent pr-8 focus:outline-none"
                      style={{
                        backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23797D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>')`,
                        backgroundPosition: "right 0.4rem center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "1.5rem"
                      }}
                    >
                      <option>+91</option>
                      <option>+44</option>
                      <option>+1</option>
                      <option>+61</option>
                      <option>+81</option>
                    </select>

                    {/* Vertical Divider */}
                    <span className="w-[1px] my-2 bg-[#797D82]"></span>

                    {/* Phone Number Input */}
                    <input
                      type="tel"
                      id="phone-number"
                      className="w-full p-2 border-none rounded-r-lg focus:outline-none focus:ring-0"
                      name='mobile_number'
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* ----------------------------------------------------------- */}
                {/* Three Select Fields */}
                <div className="grid grid-cols-1 gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="hire-plans"
                      className="text-[#797D82] text-base font-normal mb-1"
                    >
                      How many hires are you planning to make in the next 12
                      months?
                    </label>

                    {/* Wrapper div to apply focus-within */}
                    <div className="relative focus-within:border-[#1571B7] focus-within:shadow-sm focus-within:shadow-[#1571B7] border border-gray-300 rounded-lg">
                      <select
                        name='hiring_forecast' onChange={handleChange}
                        id="hire-plans"
                        className="w-full p-2 border-none rounded-lg focus:outline-none appearance-none pr-8"
                        style={{
                          backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23797D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>')`,
                          backgroundPosition: "right 1rem center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "1.5rem"
                        }}
                      >
                        <option value="">Please Select</option>
                        <option value="1-30">1-30</option>
                        <option value="31-200">31-200</option>
                        <option value="More than 200">More than 200</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="hire-plans"
                      className="text-[#797D82] text-base font-normal mb-1"
                    >
                      Number of employees in your organization
                    </label>

                    <div className="relative focus-within:border-[#1571B7] focus-within:shadow-sm focus-within:shadow-[#1571B7] border border-gray-300 rounded-lg">
                      <select
                        name='number_of_employees' onChange={handleChange}
                        id="hire-plans"
                        className="w-full p-2 border-none rounded-lg focus:outline-none appearance-none pr-8"
                        style={{
                          backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23797D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>')`,
                          backgroundPosition: "right 1rem center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "1.5rem"
                        }}
                      >
                        <option value="">Select Option</option>
                        <option value="Less than 50">Less than 50</option>
                        <option value="50-499">50-499</option>
                        <option value="More than 500">More than 500</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="text-center ">
                  <CaptchaField name="captcha" onChange={(e) => { setInputCaptcha(e.target.value) }} iscorrect={iscorrect} captcha={captcha} />
                </div>

                {/* Submit Button */}
                <div className="text-center ">
                  <button disabled={!isValidEmail} className="self-center view_all mb-14 px-6 py-3.5 mt-4  ml-2.5 max-w-full text-base font-medium text-white shadow-sm  rounded-[61px] w-[182px] max-md:px-5 max-md:mt-10" onClick={DemoSubmit}>
                    Book a call
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};

export default ContactUsForm;
