import TestimonialComponent from "../../../Components/Testimonial/TestimonialComponent";
const TestimonialSection = () => {
  return (
    <div>
      <TestimonialComponent />
    </div>
  );
};

export default TestimonialSection;
