import AboutUsHeroSection from "./AboutUsHeroSection/AboutUsHeroSection";
import AboutUsInfoSection from "./AboutUsInfoSection/AboutUsInfoSection";
import OurTeam from "./OurTeam/OurTeam";
import WhyHirestarSection from "../HomePage/WhyHirestarSection/WhyHirestarSection";
import TestimonialComponent from "../../Components/Testimonial/TestimonialComponent";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
const AboutUsPage = () => {
  return (
    <div>
      <div className="section_spacing">
        <AboutUsHeroSection />
      </div>
      <div className="section_spacing">
        <AboutUsInfoSection />
      </div>
      {/*  <div>
        <OurTeam />
      </div> */}
      <div >
        <WhyHirestarSection />
      </div>
      <div className="section_spacing">
        <TestimonialComponent />
      </div>
      <FaqComponent />
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>
  );
};

export default AboutUsPage;
