const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const PhoneCallIcon = `${digitalOceanURL}/ContactUsPageAssest/phone-call.svg`;
const addressIcon = `${digitalOceanURL}/ContactUsPageAssest/address-marker.svg`;
const mailIcon = `${digitalOceanURL}/ContactUsPageAssest/ri_mail-send-line.svg`;
const ContactCard = () => {
  return (
    <div className="w-full lg:mb-[12rem] max-w-5xl mt-14 mx-auto ">
      <div className="flex flex-col sm:flex-row justify-between bg-white rounded-lg shadow-lg p-10 space-y-8 sm:space-y-0 sm:space-x-8">
        {/* First Section: Phone details */}
        <div className="flex items-center space-x-4 w-full sm:w-auto">
          <img loading="lazy"
            src={PhoneCallIcon}
            alt="Phone Call"
            className="w-8 h-8 sm:w-6 sm:h-6"
          />
          <div>
            <p className="text-sm sm:text-base text-[#656A6D] font-normal">
              Mon to Sat: 9:00AM-6:00PM
            </p>
            <p className="text-base sm:text-lg">040-40198080</p>
          </div>
        </div>

        {/* Vertical Divider */}
        <div className="hidden sm:block w-px bg-gray-200 h-16 self-center"></div>

        {/* Second Section: Email details */}
        <div className="flex items-center space-x-4 w-full sm:w-auto">
          <img loading="lazy"
            src={mailIcon}
            alt="Address Icon"
            className="w-8 h-8 sm:w-6 sm:h-6"
          />
          <div>
            <p className="text-sm sm:text-base text-[#656A6D] font-normal">
              Do you have a question?
            </p>
            <p className="text-base sm:text-lg">admin@hirestar.io</p>
          </div>
        </div>

        {/* Vertical Divider */}
        <div className="hidden sm:block w-px bg-gray-200 h-16 self-center"></div>

        {/* Third Section: Address details */}
        <div className="flex items-center space-x-4 w-full sm:w-auto">
          <img loading="lazy"

            src={addressIcon}
            alt="Mail Icon"

            className="w-8 h-8 sm:w-6 sm:h-6"
          />
          <div>
            <p className="text-sm sm:text-base text-[#656A6D] font-normal">
              Address
            </p>
            <p className="text-base sm:text-lg">Hyderabad, Telangana 500081, India</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
