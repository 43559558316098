

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const About_Section_Image = `${digitalOceanURL}/Portal_Image/Hr_Portal_Section_Image.png`;
const wireframe = `${digitalOceanURL}/Portal_Image/wireframe.png`;

export default function Hr_Portal_Section() {
  return (
      <div className=" relative section_spacing2">
      <img loading="lazy"
        src={wireframe}
        alt="wireframe"
        className="hidden md:block absolute right-0 top-0"
      />

            <div className="grid lg:grid-cols-2 lg:mx-12 sm:grid-cols-1 sm:gap-8 About-box items-center justify-center relative lg:top-36 ">
                <div className="about-text-box text-center lg:text-left  lg:mx-0 md:mx-0 mx-2">
                    <div>
                        <button className="rounded-full text-black p-1 h-[37px] w-52 mb-2 mt-2 About-btn font-[16px]">
                            Game Changer HR Portal{" "}
                        </button>
                    </div>
                    <div className="mt-2">
                        <h2 className="font-semibold text-[32px] md:text-[48px] lg:text-[48px] About-Heading">
                            Revolutionize <span className='text-[#1783C4]'>HR Portal</span> <br /> with Automated Background Checks
                        </h2>

                    </div>
                    <div className="mt-4">
                        <p className="font-[poppins] font-[16px] ">
                            The Ultimate HR Game Changer – Hirestar.io HR Portal Bid farewell to the archaic, cumbersome methods of background verification. Hirestar.io reimagines the pre-joining vetting process, harnessing blockchain’s security and transparency. From seamless verifications to real-time updates, this portal is meticulously designed for HR professionals who aspire to lead with efficiency and precision.
                        </p>
                    </div>
                   {/*  <div className="mt-4">
                        <button className="rounded-full text-white w-[134px] h-[52px] mb-2 mt-2 Read-More-btn font-[16px]">
                            Read More{" "}
                        </button>
                    </div> */}
                </div>
                <div className="flex justify-center lg:mx-0 md:mx-0 mx-2">
                    <img loading="lazy" src={About_Section_Image} alt="About_image" />
                </div>
            </div>
        </div>


    );
}
