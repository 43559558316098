import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Components//Navbar/Navbar.jsx";
import AllRoutes from "./Pages/AllRoutes/AllRoutes.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import { useLocation } from "react-router-dom";
function App() {
  const location = useLocation();
  // console.log(location.key);
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <AllRoutes />
      {location.key !== "default" && <Footer />}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
