import { Route, Routes } from "react-router-dom";
// import React, { Suspense, lazy } from "react";
import NotFound from "../404/NotFound.jsx";
import HomePage from "../HomePage/HomePage.jsx";
import AboutSection from "../AboutUsPage/AboutUsPage.jsx";
import BlogsPage from "../BlogsPage/BlogsPage.jsx";
import ContactUsPage from "../ContactUsPage/ContactUsPage.jsx";
import Education from "../Medical_Sector/Education.jsx";
import Banking from "../Medical_Sector/Banking.jsx";
import BookDemoPage from "../BookDemo/BookDemoPage.jsx";
import Travel from "../Medical_Sector/Travel.jsx";
import ECommerce from "../Medical_Sector/E_Commerce.jsx";
import Games from "../Medical_Sector/Games.jsx";
import Manufacture from "../Medical_Sector/Manufacture.jsx";
import Logistics from "../Medical_Sector/Logistic.jsx";
import GigWorkers from "../Medical_Sector/Gig_workers.jsx";
import SingleBlogPage from "../SingleBlogPage/SingleBlogPage.jsx";
import ConsumerGoods from "../Medical_Sector/Consumer_Goods.jsx";
import MedicalSector from "../Medical_Sector/Medical_Sector.jsx";
import ItPage from "../ItPage/ItPage.jsx";
import Govt_API from "../Govt_API/Govt_API";
import Hr_Portal from "../Portal/HR_Portal";
import Self_Verification from "../Portal/Self_Verification.jsx";
import Offer_Portal from "../Portal/Offer_Portal.jsx";
import GR_Portal from "../Portal/GR_Portal.jsx";
// import OurServiceSection from "../HomePage/OurServiceSection/OurServiceSection.jsx";
// import WhyHirestarSection from "../HomePage/WhyHirestarSection/WhyHirestarSection.jsx";
import FMCG from "../Medical_Sector/FMCG.jsx";
import PageForCities from "../PagesForCities/PageForCities.jsx";

const AllRoutes = () => {
  return (
    <div>
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <Routes>

        <Route path="/" exact element={<HomePage />} />
        <Route path="/about-us" element={<AboutSection />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/book-demo" element={<BookDemoPage />} />
        {/* <Route path="/blogs/blog/:id" element={<SingleBlogPage />} /> */}
        <Route path="/blog/:slug" element={<SingleBlogPage />} />
        <Route path="/city-page/:slug" element={<PageForCities />} />
        {/* Lazy loading Industry pages */}
        <Route path="/industries/banking" element={<Banking />} />
        <Route path="/industries/healthcare" element={<MedicalSector />} />
        <Route path="/industries/online-gaming" element={<Games />} />
        <Route path="/industries/education" element={<Education />} />
        <Route path="/industries/manufacturing" element={<Manufacture />} />
        <Route path="/industries/it-ites" element={<ItPage />} />
        <Route path="/industries/travel" element={<Travel />} />
        <Route path="/industries/gig-workers" element={<GigWorkers />} />
        <Route path="/industries/ecommerce" element={<ECommerce />} />
        <Route path="/industries/logistics" element={<Logistics />} />
        <Route path="/industries/consumer-goods" element={<ConsumerGoods />} />
        <Route path="/industries/fmcg" element={<FMCG />} />
        {/* Govt API */}
        {/* -------------------------govt apis--------------------------------- */}
        <Route path="/govt-api/identity-verification" element={<Govt_API />} />
        <Route path="/govt-api" element={<Govt_API />} />
        <Route path="/govt-api/aadhaar-verification" element={<Govt_API />} />
        <Route path="/govt-api/aadhaar-via-digilocker" element={<Govt_API />} />
        <Route
          path="/govt-api/driving-license-verification"
          element={<Govt_API />}
        />
        <Route path="/govt-api/pan-card-verification" element={<Govt_API />} />
        <Route path="/govt-api/pan-aadhaar-seeding" element={<Govt_API />} />
        <Route path="/govt-api/passport-verification" element={<Govt_API />} />
        <Route path="/govt-api/voter-id-verification" element={<Govt_API />} />
        <Route path="/govt-api/aadhaar-ocr" element={<Govt_API />} />
        <Route path="/govt-api/aadhaar-masking" element={<Govt_API />} />
        <Route path="/govt-api/aadhaar-paperless" element={<Govt_API />} />
        <Route
          path="/govt-api/aadhaar-offline-verification"
          element={<Govt_API />}
        />
        <Route path="/govt-api/pan-name-dob" element={<Govt_API />} />
        <Route path="/govt-api/utilities" element={<Govt_API />} />
        <Route path="/govt-api/electricity-bill-api" element={<Govt_API />} />
        <Route path="/govt-api/rc-verification" element={<Govt_API />} />
        <Route
          path="/govt-api/stolen-vehicle-verification"
          element={<Govt_API />}
        />
        <Route path="/govt-api/email-verification" element={<Govt_API />} />
        <Route path="/govt-api/tenant-registration" element={<Govt_API />} />
        <Route path="/govt-api/entity-business-level" element={<Govt_API />} />
        <Route path="/govt-api/din" element={<Govt_API />} />
        <Route path="/govt-api/udyog-aadhaar" element={<Govt_API />} />
        <Route path="/govt-api/tin-search" element={<Govt_API />} />
        <Route path="/govt-api/gstin" element={<Govt_API />} />
        <Route path="/govt-api/udyog-aadhaar-phone" element={<Govt_API />} />
        <Route path="/govt-api/employment" element={<Govt_API />} />
        <Route path="/govt-api/uan-verification" element={<Govt_API />} />
        <Route path="/govt-api/epfo-verification" element={<Govt_API />} />
        <Route path="/govt-api/cv-validation" element={<Govt_API />} />
        <Route path="/govt-api/dual-employment-check" element={<Govt_API />} />
        <Route
          path="/govt-api/employment-default-check"
          element={<Govt_API />}
        />
        <Route path="/govt-api/banking-payments" element={<Govt_API />} />
        <Route
          path="/govt-api/bank-statement-analysis"
          element={<Govt_API />}
        />
        <Route
          path="/govt-api/upi-handle-verification"
          element={<Govt_API />}
        />
        <Route path="/govt-api/experian-credit-report" element={<Govt_API />} />
        <Route path="/govt-api/address-verification" element={<Govt_API />} />
        <Route path="/govt-api/fraud-detection" element={<Govt_API />} />
        <Route path="/govt-api/face-api-verification" element={<Govt_API />} />
        <Route path="/govt-api/liveness-check" element={<Govt_API />} />f
        <Route path="/govt-api/court-check" element={<Govt_API />} />
        <Route
          path="/govt-api/defaulting-director-check"
          element={<Govt_API />}
        />
        <Route path="/govt-api/global-sanctions-check" element={<Govt_API />} />
        <Route path="/govt-api/professional" element={<Govt_API />} />
        <Route
          path="/govt-api/insurance-agent-verification"
          element={<Govt_API />}
        />
        <Route path="/govt-api/nurses-verification" element={<Govt_API />} />
        <Route
          path="/govt-api/medical-document-validation"
          element={<Govt_API />}
        />
        <Route
          path="/govt-api/medical-opinion-validation"
          element={<Govt_API />}
        />
        <Route path="/govt-api/icsi-verification" element={<Govt_API />} />
        {/* ------------------------------------------- */}
        <Route path="/hr-portal" element={<Hr_Portal />} />
        <Route path="/self-verification" element={<Self_Verification />} />
        <Route path="/offer-portal" element={<Offer_Portal />} />
        <Route path="/grievance-portal" element={<GR_Portal />} />


        {/* -----------------notFound page--------------------- */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* </Suspense> */}
    </div>
  );
};

export default AllRoutes;
