import { Link } from "react-router-dom";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const Industry_Background = `${digitalOceanURL}/Industry/industry-background.png`;
const Industry_1 = `${digitalOceanURL}/Industry/industry_1.png`;
const Industry_2 = `${digitalOceanURL}/Industry/industry_2.png`;
const Industry_3 = `${digitalOceanURL}/Industry/industry_3.png`;
const Industry_4 = `${digitalOceanURL}/Industry/industry_4.png`;
const Industry_5 = `${digitalOceanURL}/Industry/industry_5.png`;
const Industry_6 = `${digitalOceanURL}/Industry/industry_6.png`;
const Industry_7 = `${digitalOceanURL}/Industry/industry_7.png`;
const Industry_8 = `${digitalOceanURL}/Industry/industry_8.png`;
const Industry_9 = `${digitalOceanURL}/Industry/industry_9.png`;
const Industry_10 = `${digitalOceanURL}/Industry/industry_10.png`;
const Industry_11 = `${digitalOceanURL}/Industry/industry_11.png`;
const IndustrySection = () => {
  return (
    <div className=" mt-32  lg:h-96 " id="industry">
      <div className="hidden lg:block">

        <img
          loading="lazy"
          src={Industry_Background}
          alt="industry_background"
          className="ml-5 absolute mx-12 industry-bg-img "
        />

        <div className="grid grid-cols-3 lg:grid-cols-8 lg:mx-12 justify-center items-center flex-center-children  mx-2.5">
          <div className="col-span-2 custom-sm-span-2 lg:col-span-4 flex flex-col order-1 sm:order-1 lg:order-5 ml-16 lg:ml-0 ">
            <div className="slider-heading-container text-center sm:text-center lg:text-left relative  right-10 sm:mb-8 industry_text_Section">
              <h2 className=" font-semibold mb-6  md:text-[44px] lg:text-[48px] section_headings">
                Pick Your <span className="text-[#1580C2]">Industry</span>
              </h2>
              <p className="font-[poppins] text-[16px] mt-2 industry-text">
                Explore how Hirestar.io empowers 500+ <br />companies secure screening and due diligence
              </p>
            </div>
          </div>

          <div className="relative order-2 sm:order-3 lg:order-1 lg:mt-8 md:m-6 flex flex-col items-center lg:top-8 industry_child_Section1">
            <Link to="industries/it-ites" className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 lg:-ml-4">
              <img src={Industry_1} alt="Industry_1" className="industry-img-2" />

              <div>
                <h3 className="industry-texts text-center mt-4">IT / ITeS</h3>
              </div>
            </Link>
          </div>


          <div className="relative order-3 sm:order-4 lg:order-2 lg:top-8 flex md:m-6 industry_child_Section2">
            <Link
              to="/industries/banking"
              className="flex flex-col cursor-pointer items-center transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_2}
                  alt="Industry_2"
                  className=" transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Finance</h3>
              </div>
            </Link>
          </div>

          <div className="relative order-4 sm:order-5 lg:order-3 lg:top-8 md:m-6 industry_child_Section3">
            <Link
              to="/industries/healthcare"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_3}
                  alt="Industry_3"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Medical</h3>
              </div>
            </Link>
          </div>
          <div className="relative order-5 sm:order-6 lg:order-4 lg:top-8 lg:left-2 md:m-6 industry_child_Section4 industry_child_Section8">
            <Link
              to={"/industries/logistics"}
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_4}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Logistics</h3>
              </div>
            </Link>
          </div>

          <div className="relative order-6 sm:order-7 lg:order-5 lg:top-16 flex md:m-6 industry_child_Section1 industry_child_Section8">
            <Link
              to="/industries/ecommerce"
              className="flex flex-col cursor-pointer items-center transform transition-transform duration-300 ease-in-out hover:scale-110 lg:-ml-4"
            >
              <div>
                <img loading="lazy"
                  src={Industry_5}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">E-commerce</h3>
              </div>
            </Link>
          </div>
          <div className="relative order-7 sm:order-8 lg:order-6 lg:top-16 md:m-6 industry_child_Section2 industry_child_Section8">
            <Link
              to="/industries/education"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_6}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Education</h3>
              </div>
            </Link>
          </div>
          <div className="relative order-8 sm:order-9 lg:order-7 lg:top-16  md:m-6 industry_child_Section3 industry_child_Section9">
            <Link
              to="/industries/online-gaming"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_7}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Games</h3>
              </div>
            </Link>
          </div>

          <div className="relative order-9 sm:order-10 lg:order-8 lg:top-16 lg:left-2 md:m-6 industry_child_Section4 industry_child_Section9 industry_child_Section16">
            <Link
              to="/industries/travel"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_8}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Travel</h3>
              </div>
            </Link>
          </div>
          <div className="relative order-10 sm:order-13 lg:order-11 lg:top-16 lg:left-2 md:m-6 industry_child_Section5 industry_child_Section9">
            <Link
              to="/industries/manufacturing"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_9}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Manufacturing</h3>
              </div>
            </Link>
          </div>
          <div className="relative order-11 sm:order-13 lg:order-11 lg:top-16 lg:left-3 md:m-6 industry_child_Section6 industry_child_Section10 ">
            <Link
              to="/industries/gig-workers"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 lg:ml-4"
            >
              <div>
                <img loading="lazy"
                  src={Industry_10}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>

              <div className="mt-4">
                <h3 className="industry-texts text-center text-nowrap">Gig Workers</h3>
              </div>
            </Link>
          </div>
          <div className="relative order-12 sm:order-13 lg:order-11 lg:top-16 lg:left-7 md:m-6 industry_child_Section6 industry_child_Section9">
            <Link
              to="/industries/consumer-goods"
              className="flex flex-col cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 lg:ml-4"
            >
              <div>
                <img loading="lazy"
                  src={Industry_11}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4 text-center">
                <h3 className="industry-texts">FMCG</h3>
              </div>
            </Link>
          </div>
        </div>

      </div>

      <div className="lg:hidden text-center">
        <h2 className=" font-semibold mb-2  md:text-[44px] lg:text-[48px] section_headings">
          Pick Your <span className="text-[#1580C2]">Industry</span>
        </h2>
        <p className="font-[poppins] text-[16px] mt-1 industry-text">
          Explore how Hirestar.io empowers 500+ <br />companies secure screening and due diligence
        </p>
        {/* --------------------------------- */}
        <div className="grid grid-cols-3 justify-center items-center mx-2 gap-8 mt-16">

          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link to="industries/it-ites" className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 ">
              <img src={Industry_1} alt="Industry_1" className="industry-img-2" />

              <div>
                <h3 className="industry-texts text-center mt-4">IT / ITeS</h3>
              </div>
            </Link>
          </div>


          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/banking"
              className="flex flex-col cursor-pointer items-center transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_2}
                  alt="Industry_2"
                  className=" transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Finance</h3>
              </div>
            </Link>
          </div>

          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/healthcare"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_3}
                  alt="Industry_3"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Medical</h3>
              </div>
            </Link>
          </div>
          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to={"/industries/logistics"}
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_4}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Logistics</h3>
              </div>
            </Link>
          </div>

          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/ecommerce"
              className="flex flex-col cursor-pointer items-center transform transition-transform duration-300 ease-in-out hover:scale-110 lg:-ml-4"
            >
              <div>
                <img loading="lazy"
                  src={Industry_5}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">E-commerce</h3>
              </div>
            </Link>
          </div>
          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/education"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_6}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Education</h3>
              </div>
            </Link>
          </div>
          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/online-gaming"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_7}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Games</h3>
              </div>
            </Link>
          </div>

          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/travel"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_8}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Travel</h3>
              </div>
            </Link>
          </div>
          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/consumer-goods"
              className="flex flex-col cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 lg:ml-4"
            >
              <div>
                <img loading="lazy"
                  src={Industry_11}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4 text-center">
                <h3 className="industry-texts">FMCG</h3>
              </div>
            </Link>
          </div>
          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/gig-workers"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 lg:ml-4"
            >
              <div>
                <img loading="lazy"
                  src={Industry_10}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>

              <div className="mt-4">
                <h3 className="industry-texts text-center ">Gig Workers</h3>
              </div>
            </Link>
          </div>


          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/manufacturing"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                <img loading="lazy"
                  src={Industry_9}
                  alt="Industry_4"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Manufacturing</h3>
              </div>
            </Link>
          </div>

        </div>


      </div>

    </div>
  );
};

export default IndustrySection;
