import React, { useEffect } from "react";
import "./box.style.css"; // Import the CSS file
import AOS from "aos";
import "aos/dist/aos.css";
const digitalOceanURL = "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";
const line1 = `${digitalOceanURL}/ContactHirestar/Svg/line1.svg`;
// const line2 = `${digitalOceanURL}/ContactHirestar/Svg/line-2.svg`;
const line3 = `${digitalOceanURL}/ContactHirestar/Svg/line-3.svg`;
const line4 = `${digitalOceanURL}/ContactHirestar/Svg/line-4.svg`;
// const CenIcon = `${digitalOceanURL}/ContactHirestar/hicon.png`;
const svgCenter = `${digitalOceanURL}/ContactHirestar/svgCenter.svg`;
// const centerImg = `${digitalOceanURL}/ContactHirestar/icons/center.svg`;
const offer = `${digitalOceanURL}/ContactHirestar/icons/online-pay.svg`;
const rocket = `${digitalOceanURL}/ContactHirestar/icons/rocket.svg`;
const stack = `${digitalOceanURL}/ContactHirestar/icons/stack.svg`;
const blockChain = `${digitalOceanURL}/ContactHirestar/icons/blockchain.svg`;
const Box1 = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Easing animation
      once: true // Whether animation should happen only once or every time you scroll
    });
  }, []);
  return (
    // ----------------
    <div className="box-container mb-10 w-10/12 m-auto grid lg:grid-cols-1 lg:max-w-6xl overflow-x-hidden mt-28 md:mt-10 lg:mt-24 gap-7 items-center">
      {/* Heading */}
      <h2 className=" font-semibold mb-6 md:text-[44px] lg:text-[48px] text-center section_headings">
        Why <span className="text-[#1B8ACA]">Hirestar.io</span>?
      </h2>

      {/* First row with two boxes */}
      <div className="first-row relative lg:top-20 top-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-y-14 align-items-center">
        {/* Box 1 */}
        <div
          className="box-wrapper flex flex-col md:items-center lg:items-start items-center"
          data-aos="fade-right"
        >
          <div className="box-top flex">
            <div className="box-top-icon">
              <img loading="lazy"
                src={blockChain}
                alt="offer"
                className="w-full max-w-[80px]"
              />
            </div>
            <div className="box-top-line relative top-9 left-4">
              <img loading="lazy" src={line1} alt="line1" />
            </div>
          </div>
          <div className="box-bottom text-center mt-6 lg:mt-8 md:mt-8  lg:text-start">
            <div className="box-bottom-heading">
              <h2 className="text-2xl text-[#000000] font-normal">
                Instant Blockchain Verification
              </h2>
            </div>
            <div className="box-bottom-heading mt-2 lg:mt-0 md:mt-0">
              <p className="text-[#626364] font-normal">
                Swiftly verify backgrounds, eliminating
              </p>
              <p className="text-[#626364] font-normal">
                delays with our cutting-edge Blockchain
              </p>
              <p className="text-[#626364] font-normal">technology.</p>
            </div>
          </div>
        </div>

        {/* Box 2 */}
        <div
          className="box-wrapper relative flex flex-col md:items-center md:items-start lg:items-end   md:text-start items-center"
          data-aos="fade-left"
        >
          <div className="box-top flex lg:justify-end">
            <div className="box-top-line relative top-9 right-4">
              <svg
                width="280"
                height="46"
                viewBox="0 0 280 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.5"
                  d="M1 44L51.5 1H174.5H279.561"
                  stroke="black"
                />
                <circle cx="5" cy="41" r="5" fill="#F04025" />
                <circle
                  cx="5"
                  cy="41"
                  r="5"
                  fill="url(#paint0_linear_895_139)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_895_139"
                    x1="2.37213e-07"
                    y1="34.75"
                    x2="10.6605"
                    y2="46.4264"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2AABE2" />
                    <stop offset="1" stopColor="#0D6EB5" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className="box-top-icon">
              <img loading="lazy" src={offer} alt="box2" className="w-full max-w-[80px]" />
            </div>
          </div>
          <div className="box-bottom text-center mt-6 lg:mt-8 lg:text-end">
            <div className="box-bottom-heading text-2xl  lg:mr-0">
              <h2 className="text-2xl text-[#000000] font-normal">
                Streamlined Offer-to-
                <br className="lg:block" />
                Join Journey
              </h2>
            </div>
            <div className="box-bottom-heading mt-2 lg:mt-0 md:mt-0">
              <p className="text-[#626364] font-normal lg:text-right">
                Simplify offer acceptance for candidates
              </p>
              <p className="text-[#626364] font-normal lg:text-right">
                with a user-friendly and transparent
              </p>
              <p className="text-[#626364] font-normal lg:text-right">
                process.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Center icon - Hidden on small screens */}
      <div className="second-row center-icon flex items-center justify-center">
        <img loading="lazy"
          src={svgCenter}
          alt="offer"
          className="w-full second-row-img md:w-2/5 lg:w-1/4 hirestar_Icon relative left-4 bottom-14"
        />
      </div>

      {/* Third row with two boxes */}
      <div className="third-row relative lg:bottom-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  top_space">
        {/* Box 3 */}
        <div
          className="box-wrapper flex flex-col md:items-center md:items-start lg:items-start items-center "
          data-aos="fade-right"
        >
          <div className="box-top flex">
            <div className="box-top-icon relative">
              <img loading="lazy" src={rocket} alt="box3" className="w-full max-w-[80px]" />
            </div>
            <div className="box-top-line relative top-0 left-4">
              <img loading="lazy" src={line3} alt="line3" />
            </div>
          </div>
          <div className="box-bottom mt-6 lg:mt-8 md:mt-8 text-center md:text-center lg:text-start">
            <div className="box-bottom-heading">
              <h2 className="text-2xl text-[#000000] font-normal">
                80% Boosted Joining Ratios
              </h2>
            </div>
            <div className="box-bottom-heading mt-2 lg:mt-0 md:mt-0">
              <p className="text-[#626364] font-normal">
                Achieve an 80% surge in candidate
              </p>
              <p className="text-[#626364] font-normal">
                acceptance, delivering a seamless offer-to-
              </p>
              <p className="text-[#626364] font-normal">join experience.</p>
            </div>
          </div>
        </div>

        {/* Box 4 */}
        <div
          className="box-wrapper flex flex-col md:items-center md:items-start lg:items-end  md:text-start mt-12 md:mt-0 items-center top_Space2  "
          data-aos="fade-left"
        >
          <div className="box-top flex lg:justify-end">
            <div className="box-top-line relative top-0 right-4 ">
              <img loading="lazy" src={line4} alt="line4" />
            </div>
            <div className="box-top-icon iconn_height">
              <img loading="lazy" src={stack} alt="box4" className="w-full max-w-[80px]" />
            </div>
          </div>
          <div className="box-bottom mt-6 lg:mt-8 md:mt-8 text-center md:text-center lg:text-start ">
            <div className="box-bottom-heading">
              <h2 className="text-2xl text-[#000000] font-normal">
                Integration Without Disruption
              </h2>
            </div>
            <div className="box-bottom-heading mt-2 lg:mt-0 md:mt-0">
              <p className="text-[#626364] font-normal lg:text-right">
                Seamlessly integrate our solutions
              </p>
              <p className="text-[#626364] font-normal lg:text-right">
                without causing any disruption to
              </p>
              <p className="text-[#626364] font-normal lg:text-right">
                your workflow.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Box1;
