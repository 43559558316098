import React, { memo } from "react";
import SingleBlogHeroSection from "../SingleBlogPage/components/SingleBlogHeroSection/SingleBlogHeroSection";
import Location from "../SingleBlogPage/components/locationComponent/Location";
// import { Link, useParams } from "react-router-dom";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import LinksShowingBlogTitle from "./LinksShowingBlogTitle";
import TableOfContents from "./TableOfContents";
// import slugify from "slugify";
// import { cityBlogArray } from "../../blog";
import BlogContent from "./BlogContent";
import Carousel from '../SingleBlogPage/BlogsCarousel'
import { blogArray } from "../../blog";

// const bluearrow =
//   "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/SingleBlogImg/bluearrows.svg";
const PageForCities = ({blog}) => {
  // const { slug } = useParams();
  // const [singleBlog, setSingleBlog] = useState({});

  // const blog = cityBlogArray.find(
  //   (b) => slugify(b.title, { lower: true, strict: true }) === slug
  // );
  // console.log("checking blog", blog);

  if (!blog) {
    return <p>Blog not found</p>;
  }
  return (
    <div>
      <div className="section">
        <SingleBlogHeroSection />
      </div>
      <Location indiaMap={blog.big_map} stateMap={blog.small_map} state={blog.state} />
      <LinksShowingBlogTitle title={blog.title}/>
      <TableOfContents tableContents={blog.tableContents}/>
      <BlogContent content={blog.htmlDiscription}/>
      <Carousel blogArray={blogArray} />
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>
  );
};

export default memo(PageForCities);
