

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const About_Section_Image = `${digitalOceanURL}/Portal_Image/Self_Verification_Section.png`;
const wireframe = `${digitalOceanURL}/Portal_Image/wireframe.png`;

export default function Self_Verification_Section() {
  return (
    <div className="sm:mt-4 relative">
      <img loading="lazy"
        src={wireframe}
        alt="wireframe"
        className="hidden md:block absolute right-0 top-0"
      />

      <div className="grid lg:grid-cols-2 lg:mx-12 sm:grid-cols-1 sm:gap-8 About-box items-center justify-center relative lg:top-36">
        <div className="about-text-box text-center lg:text-left lg:mx-0 md:mx-0 mx-2">
          <div>
            <button className="rounded-full text-black p-1 h-[37px] w-48 mb-2 mt-2 About-btn font-[16px]">
              Self Verification Portal{" "}
            </button>
          </div>
          <div className="mt-2">
            <h2 className="font-semibold text-[32px] md:text-[48px] lg:text-[48px] About-Heading">
              Reduce work load of HR by 80 % with <br /> <span className='text-[#1783C4]'> Self Verification Portal</span>
            </h2>
          </div>
          <div className="mt-4">
            <p className="font-[poppins] font-[16px] ">
              Hirestar.io’s self-verification portal empowers employees to
              handle their own background checks, drastically cutting down HR
              tasks. This innovative solution not only saves time but also
              ensures faster, more accurate verifications, giving HR teams the
              freedom to focus on strategic priorities while maintaining
              compliance and transparency.
            </p>
          </div>
        {/*   <div className="mt-4">
            <button className="rounded-full text-white w-[134px] h-[52px] mb-2 mt-2 Read-More-btn font-[16px]">
              Read More{" "}
            </button>
          </div> */}
          
        </div>
        <div className="flex justify-center lg:mx-0 md:mx-0 mx-2">
          <img loading="lazy" src={About_Section_Image} alt="About_image" />
        </div>
      </div>
    </div>
  );
}
