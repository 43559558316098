const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const ContactHeader = () => {
  return (
    <div
      className={`relative w-full  bg-[url( ${digitalOceanURL}/ContactUsPageAssest/header-bg.svg)]  bg-cover bg-no-repeat h-[600px] header-container`}
    >
      <div className="header-content absolute inset-0  flex flex-col justify-center items-center text-center">
        <h2 className="text-[34px] md:text-[54px] lg:text-[54px] font-medium text-white ">
          Contact us
        </h2>
        <p className="lg:text-base text-white font-normal md:w-1/2 lg:w-1/2 mx-auto text-pretty">
          We leverage cutting-edge blockchain technology to provide secure and efficient background verification services, ensuring trust and transparency in every process.
        </p>

        {/*  <p className="lg:text-base text-white font-normal md:w-1/2 lg:w-1/2 mx-auto text-pretty">
        Our innovative solutions streamline background checks across industries, offering speed, reliability, and unparalleled accuracy.
      </p> */}
      </div>
    </div >
  );
};

export default ContactHeader;
