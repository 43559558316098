// import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../Components/Buttons/ButtonComponent";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const about_bg = `${digitalOceanURL}/About_Us_img/About_bg.png`;
const about_girl = `${digitalOceanURL}/About_Us_img/about_girl.png`;
const AboutUsHeroSection = () => {
  // const navigate = useNavigate();
  return (
    <div className=" relative  background_color ">
      {/* Image that is hidden on small screens */}
      <img 
        src={about_bg}
        alt="about-us-bg"
        className="absolute  object-cover w-full lg:h-[120%] h-[115%] md:h-[120%] bg_curve_img"
      />

      {/* Container with background color */}
      <div className=" relative  w-full h-full lg:top-0 sm:top-20  bottom_space">
        <div className="grid grid-cols-12 gap-4 justify-items-center relative top-0 lg:top-32 lg:mx-20 xl:mx-32 2xl:mx-40 items-center">
          {/* Text container */}
          <div className="col-span-12 md:col-span-12 lg:col-span-7 w-full Banking_heading font-semibold font-[poppins] top_spacing ">
            <h1 className="w-full text-[32px] md:text-[40px] lg:text-[46px]">

              Enhance Your <br /> Hiring Process with Blockchain Technology
            </h1>
            <p className="about-text-para mt-2 w-[80%]">
              HireStar.io offers secure, blockchain-based background
              verification services, ensuring transparent and trustworthy hiring
              processes.
            </p>

            {/* Button Group */}
            <div className="mt-4">
              <ButtonComponent />
            </div>
          </div>

          {/* Image container */}
          <div className="col-span-12 md:col-span-12 lg:col-span-5 relative   lg:mt-0 background_color mb-20 lg:mb-0">
            <img 
              src={about_girl}
              alt="about-girl"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHeroSection;
