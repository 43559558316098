import BookDemoHeader from "./components/BookDemoHeader";
import BookDemoForm from "./components/BookDemoForm";
import "./components/contact_us.style.css";
const BookDemoPage = () => {
  return (
    <div>
      <BookDemoHeader />
      <BookDemoForm />
    </div>
  );
};

export default BookDemoPage;
