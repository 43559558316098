import BlogSearchInput from "./BlogSearchInput";
import { memo } from "react";
const BlogsHeader = ({ handleSearch, inputValue }) => {
  // console.log('blogs header');
  return (
    <div className="relative w-full header-container">
      <div className="header-content absolute inset-0  flex flex-col justify-center items-center text-center mt-12">
        <h2 className="text-[50px] md:text-[54px] lg:text-[54px] font-medium text-white uppercase">
          Blogs
        </h2>
        <p className="lg:text-base text-white font-normal lg:px-0 md:px-0  px-1   md:w-1/2  lg:w-1/3 mx-auto text-pretty">
          Explore the vital role of background verification in today's world and
          discover how blockchain technology is revolutionizing trust, security,
          and transparency in the process. Stay informed with our insights on
          the future of verification.
        </p>

        {/* search input */}
        <BlogSearchInput handleSearch={handleSearch} inputValue={inputValue} />
      </div>
    </div>
  );
};

export default memo(BlogsHeader);
