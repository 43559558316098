import React from "react";
import {memo} from "react";
const ServiceCard = ({ card, index }) => {
  return (
    <div
      key={index}
      class="service-box grid grid-cols-2 gap-4 justify-start items-center service-small-box"
    >
      <div class="boxes lg:relative md:relative">
        <svg
          width="30"
          height="36"
          viewBox="0 0 30 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className=" small_screen"
        >
          <path
            d="M14.9986 24.9779C18.0753 24.9779 20.5695 22.4838 20.5695 19.4071C20.5695 16.3304 18.0753 13.8362 14.9986 13.8362C11.9219 13.8362 9.42773 16.3304 9.42773 19.4071C9.42773 22.4838 11.9219 24.9779 14.9986 24.9779Z"
            stroke="white"
            stroke-width="1.0493"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.64062 29.1559C7.61358 27.8587 8.87522 26.8057 10.3256 26.0805C11.776 25.3553 13.3754 24.9778 14.9969 24.9778C16.6185 24.9778 18.2179 25.3553 19.6683 26.0805C21.1187 26.8057 22.3803 27.8587 23.3533 29.1559"
            stroke="white"
            stroke-width="1.0493"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M28.9258 33.3343L28.9258 2.69448C28.9258 1.9253 28.3022 1.30176 27.5331 1.30176L2.46409 1.30176C1.69491 1.30176 1.07137 1.9253 1.07137 2.69448L1.07137 33.3343C1.07137 34.1035 1.69491 34.7271 2.46409 34.7271H27.5331C28.3022 34.7271 28.9258 34.1035 28.9258 33.3343Z"
            stroke="white"
            stroke-width="1.0493"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.42773 6.87256H20.5695"
            stroke="white"
            stroke-width="1.0493"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="service_centent_box">
        <h5 className="service-heading">{card.heading}</h5>
        <p className="service-text">{card.text}</p>
      </div>
    </div>
  );
};

export default  memo(ServiceCard);
