import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { Link } from "react-router-dom";
import slugify from "slugify";

export default function Carousel({ blogArray }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 3 >= blogArray.length ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? blogArray.length - 3 : prevIndex - 1
    );
  };

  return (
    <div className="mx-4 lg:mx-0">
      <div className="relative w-full max-w-5xl mx-auto mt-[5rem] md:mt-[10rem] lg:mt-[15rem] lg:p-2 bg-white">
        {" "}
        {/* Ensure the parent has a plain white background */}
        <div className="text-center mb-8">
          <h2 className="lg:text-5xl md:text-5xl text-4xl font-semibold">
            More to <span className="text-[#1B8ACA]">Explore</span>
          </h2>
        </div>
        <div className="overflow-hidden py-8 bg-white">
          {" "}
          {/* Ensure a white background here as well */}
          <div
            className="flex transition-transform duration-300 ease-in-out"
            style={{
              transform: `translateX(-${
                currentIndex *
                (100 /
                  (window.innerWidth >= 1024
                    ? 3 // 3 cards for large screens
                    : window.innerWidth >= 768
                    ? 2 // 2 cards for medium screens
                    : 1)) // 1 card for small screens
              }%)`
            }}
          >
            {blogArray.map(({ id, title, description, image, date }) => (
              <Link
                key={id}
                to={`/blog/${slugify(title, { lower: true, strict: true })}`}
                className=" w-full sm:w-full md:w-1/2 lg:w-1/3 flex-shrink-0 px-2"
              >
                <div className="bg-white rounded-lg overflow-hidden w-full hover:shadow-lg shadow-lg transition duration-300 border-b-4 border-transparent hover:border-[#2095D2] cursor-pointer h-full flex flex-col justify-between">
                  <div>
                    <div className="relative">
                      <img loading="lazy"
                        src={image}
                        alt={title}
                        className="w-full h-40 sm:h-48 md:h-40 lg:h-48 object-cover rounded-t-lg"
                      />
                      <div className="w-36 absolute rounded-md bottom-0 left-0 right-0 bg-[#2095D2] text-center p-2 md:p-2 lg:p-2 transform translate-y-1/2 mx-2 sm:mx-5">
                        <span className="text-sm sm:text-sm font-semibold text-[#FFFFFF]">
                          {date}
                        </span>
                      </div>
                    </div>
                    <div className="p-3 mb-4 mt-4 sm:p-4 md:p-5 pt-6 sm:pt-8 flex-grow">
                      <h2 className="text-xl text-black hover:text-[#2095D2] sm:text-xl md:text-2xl font-normal mb-2 h-18 overflow-hidden">
                        {title}
                      </h2>
                      <p className="text-[#9E9E9E] font-normal text-xs sm:text-sm line-clamp-4 h-20">
                        {description}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        {/* Navigation Buttons */}
        <button
          onClick={prevSlide}
          className="absolute left-arrow top-1/2  left-0 transform -translate-y-1/2 bg-[#2095D2] rounded-full p-2 shadow-md hover:bg-gray-100 focus:outline-none"
        >
          <ChevronLeftIcon className="w-6 h-6 text-gray-600" />
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-arrow top-1/2  right-0 transform -translate-y-1/2 bg-[#2095D2] rounded-full p-2 shadow-md hover:bg-gray-100 focus:outline-none"
        >
          <ChevronRightIcon className="w-6 h-6 text-gray-600" />
        </button>
      </div>
    </div>
  );
}
