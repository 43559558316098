import React from "react";
import Box1 from "./Box1";
const ContactHirestar = () => {
  return (
    <div className=" lg:mt-72 mt-44  mx-auto">
     {/*  <div className=" text-center mb-14 slider-heading-container">
        <h1 className=" font-semibold mb-6 text-[32px] md:text-[40px] lg:text-[48px]">
          Why <span className="text-[#1B8ACA]">Hirestar.Io</span>?
        </h1>
      </div>  */}
      <Box1 />
    </div>
  );
};

export default ContactHirestar;
