import ItHeroSection from "./ItHeroSection/ItHeroSection";
import BrandCarousel from "./BrandCarousel/BrandCarousel";
import BgvServiceSection from "./BgvServiceSection/BgvServiceSection";
import BgvStepsSection from "./BgvStepsSection/BgvStepsSection";
import ServicesCardSection from "./ServicesCardSection/ServicesCardSection";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";


const ItPage = () => {
  return (
    <div>
      {/* 1 section */}
      <ItHeroSection /> 
      {/* 2 section */}
      <div className="top_Space">
        <BrandCarousel />
      </div>
      {/* 3 section */}
      {<BgvServiceSection Sector="IT/ITES Sector" />}
      {/* 4 section */}
      <ServicesCardSection Sector="IT/ITES Sector" Sector_cards='it' />
      <div className="mb-48">
        {<BgvStepsSection />}
      </div>
      <FaqComponent />
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>
  );
};

export default ItPage;
